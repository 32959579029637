import { graphql, useStaticQuery } from 'gatsby'
import { navigate } from 'gatsby-link'
import React from 'react'
import Banner from '../../components/Banner'
import Button from '../../components/Button'
import { useLangContext } from '../../context/lang.context'
import { colors, s, sTextGradientLight } from '../../style'

const content = {
  subtitle: {
    pl: 'Sea & Air Kariera',
    en: 'Sea & Air Career',
  },
  title: {
    pl: 'Dlaczego warto wybrać Omida Sea And Air S.A.?',
    en: 'Why is it worth choosing Omida Sea And Air S.A.?',
  },
  button: {
    text: {
      pl: 'Dowiedz się więcej',
      en: 'Learn more',
      ua: 'ДІЗНАТИСЯ БІЛЬШЕ ',
    },
    link: '/kariera/',
  },
}

const BannerCareer = () => {
  const { lang } = useLangContext()
  const { image } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/banners/career.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
    }
  `)

  return (
    <Banner image={image} action={() => navigate(content.button.link)}>
      <div css={sContainer}>
        <span>{content.subtitle[lang]}</span>
        <h3>{content.title[lang]}</h3>
        <Button white link={content.button.link}>
          {content.button.text[lang]}
        </Button>
      </div>
    </Banner>
  )
}

const sContainer = {
  position: 'relative',
  top: 0,
  left: 0,
  zIndex: 10,
  display: 'flex',
  flexDirection: 'column',

  span: {
    fontSize: '1rem',
    fontWeight: 700,
    color: colors.brandLight,
    marginBottom: '1rem',
    textTransform: 'uppercase',
  },

  h3: {
    fontWeight: 700,
    fontSize: '6rem',
    letterSpacing: '-0.05em',
    lineHeight: '1.2em',
    ...sTextGradientLight,
    marginBottom: '4rem',
    [s.sm_down]: {
      fontSize: '3rem',
    },
  },
}

export default BannerCareer
