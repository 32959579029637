import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import { seo, intro, main, video } from '../../content/praca'
import { useLangContext } from '../../context/lang.context'
import Intro from '../../components/Intro'
import BannerCareer from '../../sections/banners/BannerCareer'
import Main from '../../components/Main'
import JobSection from '../../sections/index/JobSection'
import Video from '../../components/Video'

const OfertyPracy = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/praca.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
    }
  `)

  const button = {
    text: {
      pl: 'Przeglądaj oferty pracy',
      en: 'Search job offers',
    },
    action: 'SCROLL',
  }

  const buttonSecond = {
    text: {
      pl: 'Kariera w Sea & Air',
      en: 'Career in Sea & Air',
    },
    link: '/kariera/',
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
      />
      <Intro
        data={{ ...intro, button, buttonSecond }}
        image={query.image.childImageSharp}
      />
      <Main
        h={2}
        s={1}
        title={main.title[lang]}
        desc={main.desc[lang]}
        subtitle={main.subtitle[lang]}>
        <JobSection />
      </Main>
      <Main h={2} title={video.title[lang]}>
        <Video video={video.video} />
      </Main>
      <BannerCareer />
    </Layout>
  )
}

export default OfertyPracy
